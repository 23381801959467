import React from 'react'
import { styles } from '../Home'
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconBrandYoutube, IconMail, IconPhone, IconPhoneCall, IconPhoneFilled, IconWorld } from '@tabler/icons-react'

const contacts = [
    {
        icon: <div className=' flex gap-2'>
            <IconBrandWhatsapp style={{ width: '40px', height: '40px' }}/>
            <IconPhoneCall style={{ width: '40px', height: '40px' }}/>
        </div>,
        value: '8129876524',
        link: 'https://wa.me/+918129876524',
    },

    {
        icon: <IconMail style={{ width: '40px', height: '40px' }} />,
        value: 'jobfirst2020@gmail.com',
        link: 'https://mail.google.com/mail/u/0/#inbox?compose=CllgCJlDTBjkLxVtTFrJzFcgQMtpDZjnDljVsWppXJwlSfcCFHZKZrJZQhrnBqhDqrxdWFGFfsV',
    },
    {
        icon: <IconBrandFacebook style={{ width: '40px', height: '40px' }}/>,
        value: 'Jobfirst2020',
        link: 'https://www.facebook.com/jobfirst2020',
    },
    {
        icon: <IconBrandInstagram style={{ width: '40px', height: '40px' }}/>,
        value: 'jobfirst2020',
        link: 'https://www.instagram.com/jobfirst2020/',
    },
    {
        icon: <IconWorld style={{ width: '40px', height: '40px' }}/>,
        value: 'www.jobfirst2020.com',
        link: 'https://www.jobfirst2020.com',
    },
    {
        icon: <IconBrandYoutube style={{ width: '40px', height: '40px' }}/>,
        value: 'jobfirst7299',
        link: 'https://www.youtube.com/@jobfirst7299',
    },
]

export default function ContactUs() {
    return (
        <>
            <h1 className='animate heading library' style={styles.heading}>Contact Us</h1>
            <p className='animate bank_partner' style={styles.text}>Get in touch with us for any queries or assistance.</p>

            <div className='main_d mt-5 max-w-2xl px-6 items-center justify-center gap-x-7' style={{ display: 'flex',flexWrap:'wrap',flexDirection:'row' }}>
                {contacts.map(contact => (
                    <div cl style={{ display: 'flex'}} className='mt-5 flex items-center justify-center gap-2'>
                        {contact.icon}
                        <a className='' href={contact.link}>{contact.value}</a>
                    </div>
                ))}
            </div>

        </>
    )
}


