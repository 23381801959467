import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { InputField, TextAreaField } from "../../component/inputs/normalInput";
import { AlertCircle, Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../shad_components/ui/alert';
import ReCAPTCHA from "react-google-recaptcha";

export const CareerForm = () => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm();
    const [submitted, setSubmitted] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [recaptchaToken, setRecaptchaToken] = React.useState(null);
    const captcharef = useRef(null)

    const clearCaptcha = () => {
        setRecaptchaToken(null)
        setValue('captchaField', '')
        if (captcharef.current) {
            captcharef.current.reset?.();
        }

    }
    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('address', data.address);
            formData.append('mobile', data.mobile);
            formData.append('email', data.email);
            formData.append('educationQualification', data.educationQualification);
            formData.append('workExperience', data.workExperience);
            formData.append('dob', data.dob);
            formData.append('resume', data.resume[0]); // Append the file
            formData.append('recaptchaToken', recaptchaToken); // Append the file

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-application`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Form submitted:', response.data);
            setSubmitted(true);
            setSubmitError(null);
            clearCaptcha()
            reset();
        } catch (error) {
            clearCaptcha()
            console.error('Error submitting form:', error);
            if (error.response?.data?.message === 'Captcha verification failed') {
                console.log('first')
                setError('captchaField', { message: 'Captcha verification failed', type: 'manual' })
                clearCaptcha()
            } else {
                setSubmitError(error.response?.data?.message || 'Failed to submit application')
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onRecaptchaChange = (value) => {
        // console.log(value)
        setValue('captchaField', value)
        if (value) {
            setError('captchaField', '')
        }
        if (value) {
            setRecaptchaToken(value)
        }
    };

    return (
        <div className="animate max-w-md md:max-w-lg md:min-w-[550px] mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Career Application</h2>
            {submitted ? (
                <Alert>
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Success!</AlertTitle>
                    <AlertDescription>
                        Your application has been submitted successfully.
                    </AlertDescription>
                </Alert>
            ) : submitError ? (
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{submitError}</AlertDescription>
                    <div className="flex items-center justify-center mt-4">
                        <button
                            type="button"
                            onClick={() => setSubmitError(null)}
                            className="text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 py-2 px-4 rounded-md transition duration-300 ease-in-out"
                        >
                            Retry
                        </button>
                    </div>
                </Alert>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        label="Full Name"
                        placeholder="Enter your full name"
                        type="text"
                        id="name"
                        disabled={isLoading}
                        {...register("name", {
                            required: "Name is required",
                            minLength: { value: 2, message: "Name must be at least 2 characters long" }
                        })}
                        error={errors.name?.message}
                    />
                    <TextAreaField
                        label="Address"
                        placeholder="Enter your address"
                        type="text"
                        id="address"
                        disabled={isLoading}
                        {...register("address", {
                            required: "Address is required"
                        })}
                        error={errors.address?.message}
                    />
                    <InputField
                        label="Mobile Number"
                        type="tel"
                        id="mobile"
                        placeholder="Enter your mobile number"
                        disabled={isLoading}
                        {...register("mobile", {
                            required: "Mobile number is required",
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Please enter a valid 10-digit mobile number"
                            }
                        })}
                        error={errors.mobile?.message}
                    />
                    <InputField
                        label="Date of Birth"
                        type="date"  // Set type to "date" for date picker
                        id="dob"  // Update the id to reflect the date of birth field
                        disabled={isLoading}
                        {...register("dob", {  // Update the name to "dob" for consistency
                            required: "Date of birth is required",  // Update the validation message accordingly
                        })}
                        error={errors.dob?.message}  // Update error handling for the "dob" field
                    />


                    <InputField
                        label="Email Address"
                        type="email"
                        id="email"
                        placeholder="Enter your email address"
                        disabled={isLoading}
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address"
                            }
                        })}
                        error={errors.email?.message}
                    />
                    <label for="educationQualification" className="block text-sm font-medium text-gray-700 mb-3">EDUCATION QUALIFICATION + WORK EXP</label>
                    <div className=' flex flex-col '>
                        {/* <label for='educationQualification' className='text-xs font-normal text-gray-700'>Educational Qualification</label> */}
                        <p className=' text-red-500 text-xs'>{errors.educationQualification?.message||errors.workExperience?.message}</p>
                        <TextAreaField
                            // label="EDUCATION + WORK EXP"
                            id="educationQualification"
                            placeholder={`1- Education qualification ...\n\n2- Work exp ... \n\n`} // Placeholder with the desired format
                            disabled={isLoading}
                            {...register("educationQualification", {
                                required: "Educational qualification and work exprerience is required"
                            })}
                            rows={5}
                        />
                        {/* <label for="workExperience" className='text-xs font-normal text-gray-700'>Work Experience</label> */}
                        {/* <textarea
                            // label="Work Experience"
                            id="workExperience"
                            className='border-t-0 resize-none'
                            placeholder={`1- Work experience 1\n2- Work experience 2`} // Placeholder with the desired format
                            rows={5}
                            disabled={isLoading}
                            {...register("workExperience", {
                                required: "Work experience and Educational qualification is required"
                            })}
                        /> */}
                    </div>


                    <div className="mb-4">
                        <label htmlFor="resume" className="block text-sm font-medium text-gray-700 mb-1">
                            Resume <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="file"
                            id="resume"
                            disabled={isLoading}
                            {...register("resume", {
                                required: " is required"
                            })}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.resume && <p className="text-red-500 text-xs mt-1">{errors.resume.message}</p>}
                    </div>
                    {/* Your form fields */}
                    <ReCAPTCHA
                        ref={captcharef}
                        sitekey="6LetLDMqAAAAAKIUd6_mbWZlEOZBkJC57UwYphHu"
                        onChange={onRecaptchaChange}
                        onExpired={clearCaptcha}
                        onError={(err) => console.log(err)}
                    />
                    <div className=' mb-2'></div>
                    <input
                        id="captchaField"
                        disabled={isLoading}
                        {...register("captchaField", {
                            required: "Need to verify captcha",
                        })}
                        className="w-full sr-only px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {errors.captchaField && <p className="text-red-500 text-xs mt-1 mb-3">{errors.captchaField.message}</p>}
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full bg-[rgb(95,11,102)] text-white py-2 px-4 rounded-md hover:bg-[rgb(144,72,139)] transition duration-300 flex items-center justify-center"
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            'Submit Application'
                        )}
                    </button>
                </form>
            )}
        </div>
    );
};